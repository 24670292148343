import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  Grid,
  TextField,
  FormControlLabel,
  Paper,
  Button,
  Link,
} from "@mui/material";
import { setUserSession } from "../../utility/userUtil";
import { Formik } from "formik";
import * as Yup from "yup";
import { useUserContext } from "../../customHook/UserContext";
import { isNullorUndefined } from "../../utility/validator";
import { useAlert } from "../../customHook/useAlert";
import * as ApiConstant from "../../services/apiConfiguration";
import { LogonApiRequest } from "../../services/api";
import { COMPANY_PAGE } from "../../constant/routeConstant";
import {
  Record_Failure_Message,
  Service_Not_Available,
} from "../../constant/messages";

const initialFormValues = {
  Username: "",
  Password: "",
};

const validationSchema = Yup.object().shape({
  Username: Yup.string().required("Username is required"),
  Password: Yup.string().required("Password is required"),
});

const LoginPage = () => {
  const [checked, setChecked] = useState(true);
  const showAlert = useAlert();
  const history = useNavigate();
  // const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    apiValue: { setAccountConfig },
  } = useUserContext();

  useEffect(() => {
    setAccountConfig(null);
  }, []);

  // handle button click of login form
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    //setError(null);
    //setLoading(true);

    if (
      !isNullorUndefined(values.Username) &&
      !isNullorUndefined(values.Password)
    ) {
      const inputRequest = {
        username: values.Username,
        password: values.Password,
      };

      LogonApiRequest(inputRequest)
        .then((response) => {
          setLoading(false);
          if (!isNullorUndefined(response.data) && response.data.isValid) {
            const {
              userName,
              userId,
              companyLogo,
              cssFileName,
              recordId,
              type,
            } = response.data;
            const userInfo = {
              userName,
              userId,
              recordId,
              companyLogo,
              cssFileName,
              type,
            };
            // localStorage.setItem("userId", response.data?.userId);
            setUserSession(response.data.token, userInfo);
            history(type === 1 ? COMPANY_PAGE : "/sales/leads");
            if (!isNullorUndefined(userName))
              showAlert("Welcome " + userName, "success");
          } else {
            resetForm();
            setSubmitting(false);
            showAlert("Invalid username or password", "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          resetForm();

          if (error?.response?.status === 401)
            showAlert(error.response.data.message);
          else {
            if (
              error?.name === ApiConstant.AXIOS_ERROR ||
              error?.code === ApiConstant.NETWORK_ERROR
            )
              showAlert(Service_Not_Available, "error");
            else showAlert(Record_Failure_Message, "error");
          }
        });
    } else {
      resetForm();
      setSubmitting(false);
      showAlert("Username and Password required", "warning");
    }
  };

  return (
    <React.Fragment>
      <div style={{ padding: 30 }}>
        <Grid>
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                handleReset,
              } = props;
              return (
                <Paper>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={3}
                      direction={"column"}
                      justify={"center"}
                      alignItems={"center"}
                      sx={{ pb: 15 }}
                    >
                      <Grid item xs={12}>
                        <TextField
                          label="Username"
                          name="Username"
                          type="text"
                          fullWidth
                          error={errors.Username && touched.Username}
                          value={values.Username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.Username &&
                            touched.Username &&
                            errors.Username
                          }
                        ></TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Password"
                          type="password"
                          name="Password"
                          fullWidth
                          error={errors.Password && touched.Password}
                          value={values.Password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.Password &&
                            touched.Password &&
                            errors.Password
                          }
                        ></TextField>
                      </Grid>
                      <Link color="rgb(26,115,232)" href="/forgotpassword">
                        {"Forgot Password?"}
                      </Link>
                      <Link color="rgb(26,115,232)" href="/signup">
                        {"Registration"}
                      </Link>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(!checked)}
                              label={"Keep me logged in"}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                          label="Keep me logged in"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                          onClick={handleSubmit}
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              );
            }}
          </Formik>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
