import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Grid, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { Formik } from "formik";
import { useUserContext } from "../../customHook/UserContext";
import {
  isNullorUndefined,
  isNullorUndefinedValue,
} from "../../utility/validator";
import LogoIndicator from "../../controls/LogoIndicator";
import { useAlert } from "../../customHook/useAlert";
import {
  Product_Record_Not_Found_Message,
  Invoice_Terms_and_Conditions,
  Invoice_Description,
  Invoice_Status,
  Product_Description,
} from "../../constant/messages";
import { ProcessApiRequest } from "../../services/api";
import * as ApiConfig from "../../services/apiConfiguration";

const initialDefaultValues = {
  invoiceName: "",
  accountName: "",
  assigned: "",
  productName: "",
  quantity: "",
  amount: "",
};

function Invoice({ handleNext, handleBack }) {
  const CHARACTER_LIMIT = 20;
  const [isLoading, setIsLoading] = useState(false);
  const showAlert = useAlert();
  const [isDisabled, setDisabled] = useState(true);
  const {
    apiValue: { accessToken, accountConfig, setAccountConfig },
  } = useUserContext();
  const [formValues, setFormValues] = useState(initialDefaultValues);

  useEffect(() => {
    PrePopulateDetails();
  }, []);

  const PrePopulateDetails = () => {
    setIsLoading(true);
    const CompanyName = accountConfig?.CompanyInfo?.Account_Name;
    const UserDetails = accountConfig?.UserInfo;
    const Products = accountConfig?.Products;

    const ProductAmount = !isNullorUndefined(Products?.Unit_Price)
      ? Products.Unit_Price
      : 0;

    if (ProductAmount === 0) {
      showAlert(Product_Record_Not_Found_Message, "error");
    } else {
      setDisabled(false);
    }

    const initialFormValues = {
      invoiceName: "",
      accountName: isNullorUndefinedValue(CompanyName),
      assigned: `${isNullorUndefinedValue(
        UserDetails?.First_Name
      )} ${isNullorUndefinedValue(UserDetails?.Last_Name)}`,
      productName: Products?.Product_Name,
      quantity: "1",
      amount: `$ ${ProductAmount}`,
    };
    setFormValues(initialFormValues);
    setIsLoading(false);
  };

  const handleSubmitClick = async () => {
    const companyInfo = accountConfig?.CompanyInfo;
    const AddressInfo = accountConfig?.AddressInfo;
    const UserDetails = accountConfig?.UserInfo;
    const Products = accountConfig?.Products;
    const SalesOrder = accountConfig?.SalesOrder;

    if (isNullorUndefined(SalesOrder) && !isNullorUndefined(companyInfo)) {
      const orderRequestObj = {
        Product_Name: Products.id,
        quantity: 1,
        Description: Product_Description,
      };

      const current_date = new Date();
      const formattedDate = current_date.toISOString().split("T")[0];

      const salesRequestObj = {
        Subject: Products.Product_Name,
        Due_Date: formattedDate,
        Contact_Name: UserDetails?.id,
        Account_Name: companyInfo?.id,
        Status: Invoice_Status,

        Shipping_Street: AddressInfo.Shipping_Street,
        Shipping_City: AddressInfo.Shipping_City,
        Shipping_State: AddressInfo.Shipping_State,
        Shipping_Country: AddressInfo.Shipping_Country,
        Shipping_Code: AddressInfo.Shipping_Code,
        // Billing_Street : "",
        // Billing_City: "Billing_City",
        // Billing_State: "Billing_State",
        // Billing_Code: "Billing_Code",
        // Billing_Country: "Billing_Country",

        Ordered_Items: [orderRequestObj],
        Terms_and_Conditions: Invoice_Terms_and_Conditions,
        Description: Invoice_Description,
      };

      await ProcessApiRequest(
        accessToken.access_token,
        ApiConfig.Action_SalesOrder,
        salesRequestObj
      )
        .then(function (apiResponse) {
          console.log(apiResponse);
          if (
            !isNullorUndefined(apiResponse) &&
            !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
          ) {
            let updateAccountConfig = { ...accountConfig };
            updateAccountConfig.SalesOrder = {
              SalesOrderId: apiResponse.data.results.id,
            };
            setAccountConfig(updateAccountConfig);
          } else {
            //showAlert(Record_Failure_Message, "error");
          }
        })
        .catch(function (exception) {
          const errorResponse = exception?.response;
          if (
            exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
            !isNullorUndefined(errorResponse) &&
            !isNullorUndefined(errorResponse?.data) &&
            errorResponse?.data?.error &&
            errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
          ) {
            //showAlert(Token_Expired_Message, "error");
            //RequestToken();
            //navigate(LOGIN_PAGE);
          } else {
            //showAlert(Record_Failure_Message, "error");
          }
        });
    }
    setIsLoading(false);
    handleNext();
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} direction="column" alignItems="center">
        {isLoading ? (
          <LogoIndicator />
        ) : (
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <Box
                  px={{
                    xs: 0,
                    sm: 5,
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    justifyContent={"center"}
                  >
                    <Typography variant="h5" style={{ color: "#1976d2" }}>
                      Order Summary
                    </Typography>
                  </Grid>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      error={errors.accountName && touched.accountName}
                      label="Account Name"
                      name="accountName"
                      // className={classes.textField}
                      value={values.accountName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.accountName &&
                        touched.accountName &&
                        errors.accountName
                      }
                      fullwidth={true}
                      margin="normal"
                      variant="outlined"
                      inputProps={{
                        maxlength: CHARACTER_LIMIT,
                        readOnly: true,
                      }}
                      sx={{
                        width: 300,
                      }}
                    />
                    <div>
                      <TextField
                        error={errors.assigned && touched.assigned}
                        label="Assigned To"
                        name="assigned"
                        // className={classes.textField}
                        value={values.assigned}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.assigned && touched.assigned && errors.assigned
                        }
                        fullwidth
                        margin="normal"
                        variant="outlined"
                        sx={{
                          width: 300,
                        }}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        error={errors.productName && touched.productName}
                        label="Product Name"
                        name="productName"
                        // className={classes.textField}
                        value={values.productName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.productName &&
                          touched.productName &&
                          errors.productName
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                        fullwidth
                        margin="normal"
                        variant="outlined"
                        sx={{
                          width: 300,
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        error={errors.quantity && touched.quantity}
                        label="Quantity"
                        name="quantity"
                        // className={classes.textField}
                        value={values.quantity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.quantity && touched.quantity && errors.quantity
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                        fullwidth
                        margin="normal"
                        variant="outlined"
                        sx={{
                          width: 300,
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        error={errors.amount && touched.amount}
                        label="Amount"
                        name="amount"
                        // className={classes.textField}
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.amount && touched.amount && errors.amount
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                        fullwidth
                        margin="normal"
                        variant="outlined"
                        sx={{
                          width: 300,
                        }}
                      />
                    </div>

                    <DialogActions>
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        disabled={isDisabled}
                        onClick={() => handleSubmitClick()}
                      >
                        Next
                      </Button>
                      {/* <DisplayFormikState {...props} /> */}
                    </DialogActions>
                  </form>
                </Box>
              );
            }}
          </Formik>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default Invoice;
