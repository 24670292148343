import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Payment from "../payment/payment-component";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Stripe from "stripe";
import { useUserContext } from "../../customHook/UserContext";
import {
  isNullorUndefinedValue,
  isNullorUndefined,
} from "../../utility/validator";
import StateList from "../../assets/constants/stateList.json";
import LogoIndicator from "../../controls/LogoIndicator";
import { useAlert } from "../../customHook/useAlert";
import { Product_Record_Not_Found_Message } from "../../constant/messages";
import { ConfigProvider } from "../../config/Provider";

const stripe = new Stripe(ConfigProvider().PAYMENT_SECRET_KEY);

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function PaymentIntegrationComponent({
  handleNext,
  handleBack,
}) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState(null);
  const showAlert = useAlert();
  const {
    apiValue: { accountConfig },
  } = useUserContext();

  useEffect(() => {
    setTimeout(() => {
      setStripePromise(loadStripe(ConfigProvider().PAYMENT_PUBLIC_KEY));
    }, 300);
  }, []);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setClientSecret();
  //   }, 300);
  // }, []);

  useEffect(() => {
    setIsLoading(true);
    createPaymentDetails();
    // setTimeout(() => {
    //   createPayment();
    // }, 300);
    setIsLoading(false);
  }, []);

  const createPaymentDetails = async () => {
    setIsLoading(true);
    const UserDetails = accountConfig?.UserInfo;
    const AddressInfo = accountConfig?.AddressInfo;
    const Products = accountConfig?.Products;

    if (isNullorUndefined(Products))
      showAlert(Product_Record_Not_Found_Message, "error");

    const ProductAmount = !isNullorUndefined(Products?.Unit_Price)
      ? (parseFloat(Products.Unit_Price) * 100).toString()
      : 1000;
    const cardHolderName = `${isNullorUndefinedValue(
      UserDetails?.First_Name
    )} ${isNullorUndefinedValue(UserDetails?.Last_Name)}`;

    if (!isNullorUndefined(AddressInfo)) {
      const stateObj = StateList.find(
        (st) => st.state === AddressInfo.Shipping_State
      );

      const savedBillingInfo = {
        name: cardHolderName,
        street: AddressInfo.Shipping_Street,
        suite: AddressInfo.Shipping_Suite,
        city: AddressInfo.Shipping_City,
        state: stateObj?.stateCode,
        zip: AddressInfo.Shipping_Code,
        country: AddressInfo.Shipping_Country,
      };

      const billingInfoObject = {
        description: Products?.Product_Name,
        shipping: {
          name: savedBillingInfo.name,
          address: {
            line1: savedBillingInfo.street,
            city: savedBillingInfo.city,
            state: savedBillingInfo.state,
            postal_code: savedBillingInfo.zip,
            country: savedBillingInfo.amount,
          },
        },
        amount: ProductAmount,
        currency: "USD",
        payment_method_types: ["card"],
      };

      setBillingDetails(savedBillingInfo);

      const paymentIntents = await stripe.paymentIntents.create(
        billingInfoObject
      );
      setClientSecret(paymentIntents?.client_secret);
    }
    setIsLoading(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {isLoading ? (
          <LogoIndicator />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {stripePromise && clientSecret && (
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <Payment handleNext={handleNext} handleBack={handleBack} />
                  </Elements>
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
