import React, { Fragment, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import {
  Grid,
  Box,
  Switch,
  Typography,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useAlert } from "../../customHook/useAlert";
import {
  Payment_successful_Message,
  Payment_unsuccessful_Message,
  Payment_Submitted_Status,
} from "../../constant/messages";
import {
  insertPaymentApi,
  ProcessApiPutRequest,
  ParallelApiCalls,
} from "../../services/api";
import * as ApiConfig from "../../services/apiConfiguration";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../customHook/UserContext";
import { ORDER_CONFIRM_PAGE } from "../../constant/routeConstant";
import LogoIndicator from "../../controls/LogoIndicator";
import StateList from "../../assets/constants/stateList.json";
import { USA_TEXT } from "../../constant/formFields";
import { COMPLETE_PAGE } from "../../constant/routeConstant";
import {
  isNullorUndefinedValue,
  isNullorUndefined,
} from "../../utility/validator";
import { getUser } from "../../utility/userUtil";
import { ConfigProvider } from "../../config/Provider";

const initialDefaultValues = {
  name: "",
  street: "",
  suite: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  business_address: false,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required."),
  street: Yup.string().trim().required("Street is required"),
  city: Yup.string().trim().required("City is required"),
  state: Yup.string().required("State is required"),
  // zip: Yup.string().required("Zip is required"),
  // country: Yup.string().trim().required("Country is required"),
});

function PaymentForm({ handleNext, handleBack }) {
  const stripe = useStripe();
  const showAlert = useAlert();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const loggedInUser = getUser();
  const {
    apiValue: { accessToken, accountConfig, setAccountConfig },
  } = useUserContext();
  const [formValues, setFormValues] = useState(initialDefaultValues);

  useEffect(() => {
    PrePopulateDetails(false);
  }, []);

  const PrePopulateDetails = (isBusinessAddress) => {
    setIsLoading(true);
    const UserDetails = accountConfig?.UserInfo;
    const cardHolderName = `${isNullorUndefinedValue(
      UserDetails?.First_Name
    )} ${isNullorUndefinedValue(UserDetails?.Last_Name)}`;

    if (isBusinessAddress) {
      if (accountConfig?.AddressInfo) {
        const {
          Shipping_Street,
          Shipping_Suite,
          Shipping_City,
          Shipping_State,
          Shipping_Code,
          Shipping_Country,
        } = accountConfig?.AddressInfo;

        const stateObj = StateList.find((st) => st.state === Shipping_State);

        const savedAddressInfo = {
          name: cardHolderName,
          street: Shipping_Street,
          suite: Shipping_Suite,
          city: Shipping_City,
          state: stateObj?.stateCode,
          zip: Shipping_Code,
          country: Shipping_Country,
          business_address: isBusinessAddress,
        };
        setFormValues(savedAddressInfo);
      } else {
        const userValues = { ...initialDefaultValues, name: cardHolderName };
        setFormValues(userValues);
      }
    } else {
      const userValues = { ...initialDefaultValues, name: cardHolderName };
      setFormValues(userValues);
    }
    setIsLoading(false);
  };

  const inputChangeHandler = (event, handleChange) => {
    var targetCheck = event.target.checked;
    PrePopulateDetails(targetCheck);
    handleChange(event);
  };

  const finalSubmit = async (data) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error, paymentIntent } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: ConfigProvider().APP_URL + ORDER_CONFIRM_PAGE,
      },
      redirect: "if_required",
    });

    setIsLoading(true);
    setIsProcessing(true);

    if (
      !isNullorUndefined(paymentIntent) &&
      paymentIntent?.status === "succeeded"
    ) {
      showAlert(Payment_successful_Message, "success");

      console.log(data);

      const stateObj = StateList.find((st) => st.stateCode === data.state);

      let requestObject = {
        Account_Status: Payment_Submitted_Status,
        Payment_Confirmation: paymentIntent.id,
        Billing_Street: data.street,
        Billing_Suite: data.suite,
        Billing_City: data.city,
        Billing_State: stateObj.state,
        Billing_Code: data.zip,
        Billing_Country: USA_TEXT,
      };

      let updateAccountConfig = { ...accountConfig };
      updateAccountConfig.CompanyInfo.Account_Status = Payment_Submitted_Status;
      setAccountConfig(updateAccountConfig);

      const requestJsonObject = {
        record_Id: accountConfig?.CompanyInfo?.id,
        data: {
          Section: ApiConfig.Section_Payment,
          InputRequest: requestObject,
        },
      };

      const requestObj2 = {
        transaction_id: paymentIntent.id,
        amount: paymentIntent.amount,
        currency: paymentIntent.currency,
        status: paymentIntent.status,
        userId: loggedInUser.recordId,
        ...data,
      };

      const paymentUpdateApiRequest = ProcessApiPutRequest(
        accessToken.access_token,
        ApiConfig.Action_Accounts,
        requestJsonObject
      );
      const paymentSaveApiRequest = insertPaymentApi(requestObj2);

      await ParallelApiCalls(paymentUpdateApiRequest, paymentSaveApiRequest)
        .then((apiResponse) => {
          console.log(apiResponse);
        })
        .catch((err) => {
          console.log(err);
        });

      navigate(
        COMPLETE_PAGE,
        {
          state: {
            confirmationNo: paymentIntent.id,
          },
        },
        { replace: true }
      );
    } else {
      if (error) {
        // Show error to your customer (for example, payment details incomplete)
        showAlert(error.message, "error");
      } else showAlert(Payment_unsuccessful_Message, "error");
    }
    setIsLoading(false);
    setIsProcessing(false);
  };

  return (
    <Fragment>
      <Grid container spacing={1} direction="column" alignItems="center">
        {isLoading ? (
          <LogoIndicator />
        ) : (
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              finalSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleReset,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <Box
                  px={{
                    xs: 0,
                    sm: 5,
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    justifyContent={"center"}
                  >
                    <Typography variant="h5" style={{ color: "#1976d2" }}>
                      Payment Details
                    </Typography>
                  </Grid>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      error={errors.name && touched.name}
                      label="Card Holder"
                      name="name"
                      fullWidth
                      placeholder="Card Holder Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.name && errors.name}
                      size="small"
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={values.business_address}
                          onChange={(e) => {
                            inputChangeHandler(e, handleChange);
                          }}
                          name="business_address"
                        />
                      }
                      label="Use Business Address"
                    />
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 2 }}>
                      <Grid item xs={12} sm={9}>
                        <TextField
                          error={errors.street && touched.street}
                          label="Street"
                          name="street"
                          value={values.street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.street && errors.street}
                          margin="dense"
                          fullWidth
                          size="small"
                          disabled={values?.business_address}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          label="Suite/Apt"
                          name="suite"
                          value={values.suite}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          margin="dense"
                          fullWidth
                          size="small"
                          disabled={values?.business_address}
                        />
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <TextField
                          error={errors.city && touched.city}
                          label="City"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.city && errors.city}
                          margin="dense"
                          fullWidth
                          size="small"
                          disabled={values?.business_address}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          select
                          label="State"
                          margin="dense"
                          name="state"
                          fullWidth
                          size="small"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.state && errors.state}
                          error={errors.state && touched.state}
                          disabled={values?.business_address}
                        >
                          {StateList.map((st) => (
                            <MenuItem key={st.state} value={st.stateCode}>
                              {st.state}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <PaymentElement />
                    <DialogActions>
                      <Button
                        type="button"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleBack}
                        disabled={isProcessing}
                      >
                        Back
                      </Button>
                      <Button type="submit">
                        {isProcessing ? "Processing..." : "Confirm"}
                      </Button>
                    </DialogActions>
                  </form>
                </Box>
              );
            }}
          </Formik>
        )}
      </Grid>
    </Fragment>
  );
}

export default PaymentForm;
