import React from "react";
import { Grid, Paper, Box, Typography, Link } from "@mui/material";
import StandardHeader from "./PublicHeader";

const PageNotFound = () => {
  return (
    <Grid container spacing={1} direction="column" alignItems="center">
      <StandardHeader />
      <Paper
        style={{ minHeight: "450px" }}
        sx={{ mt: 10, textAlign: "center" }}
        elevation={4}
        square={false}
      >
        <Box
          px={{
            xs: 0,
            sm: 5,
          }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{ mt: 10, mb: 2, textAlign: "center" }}
            justifyContent={"center"}
          >
            <Typography variant="h3" style={{ color: "red" }}>
              404
            </Typography>
            <Grid
              container
              xs={12}
              sx={{ mt: 3, mb: 2, textAlign: "center" }}
              justifyContent={"center"}
            >
              <Typography variant="h4" style={{ color: "red" }}>
                Page Not Found
              </Typography>
            </Grid>
            <div>
              The resource requested could not be found on this server! Back to{" "}
              <Link href="/login" underline="always">
                Login
              </Link>
            </div>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};
export default PageNotFound;
