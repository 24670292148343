import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Grid, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { Formik } from "formik";
import * as Yup from "yup";
import { isNullorUndefined } from "../../utility/validator";
import {
  ProcessApiRequest,
  generateToken,
  ProcessApiPutRequest,
} from "../../services/api";
import * as ApiConfig from "../../services/apiConfiguration";
import { useAlert } from "../../customHook/useAlert";
import { useUserContext } from "../../customHook/UserContext";
import {
  Record_Success_Message,
  Record_Failure_Message,
  Token_Expired_Message,
  User_Record_Exist_Message,
  Company_Record_Not_Found_Message,
} from "../../constant/messages";
import * as Formatter from "../../utility/formValidations";
import LogoIndicator from "../../controls/LogoIndicator";
import { LOGIN_PAGE, AGREEMENT_PAGE } from "../../constant/routeConstant";

const initialDefaultValues = {
  First_Name: "",
  Last_Name: "",
  Email: "",
  Mobile: "",
};

const validationSchema = Yup.object().shape({
  First_Name: Yup.string()
    .required("First Name is required")
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
  Last_Name: Yup.string()
    .required("Last Name is required")
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
  Email: Yup.string()
    .email("Invalid Email")
    .required("Email is required")
    .min(3, "Too Short!")
    .max(30, "Too Long!"),
});

function Contact({ handleNext, handleBack }) {
  const showAlert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [formValues, setFormValues] = useState(initialDefaultValues);
  const navigate = useNavigate();
  const CHARACTER_LIMIT = 30;
  const {
    apiValue: { accessToken, setAccessToken, accountConfig, setAccountConfig },
  } = useUserContext();

  useEffect(() => {
    RequestToken();
    if (!isNullorUndefined(accessToken)) {
      PrePopulateUserDetails();
    }
  }, []);

  const RequestToken = async () => {
    const current_time = new Date();
    if (
      isNullorUndefined(accessToken) ||
      Math.round(current_time.getTime() / 1000) > accessToken.expires_in
    ) {
      var tokenValue = await generateToken();
      setAccessToken(tokenValue);
    }
  };

  const PrePopulateUserDetails = async () => {
    const userInfo = accountConfig?.UserInfo;

    if (!isNullorUndefined(userInfo)) {
      const mobileFormat = !isNullorUndefined(userInfo.Mobile)
        ? Formatter.phoneNumberAutoFormat(userInfo.Mobile)
        : "";
      const savedUserInfo = {
        First_Name: userInfo.First_Name,
        Last_Name: userInfo.Last_Name,
        Email: userInfo.Email,
        Mobile: mobileFormat,
      };
      setMobileNumber(mobileFormat);
      setFormValues(savedUserInfo);
    }

    setIsLoading(false);
  };

  const inputChangeHandler = (event, fieldId, handleChange, setFieldValue) => {
    var targetVal = event.target.value;

    if (fieldId === "Mobile") {
      const formattedValue = Formatter.phoneNumberAutoFormat(targetVal);
      setMobileNumber(formattedValue);
      setFieldValue(fieldId, "formattedValue");
    }
    handleChange(event);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    let requestObject = { ...values };
    const userInfo = accountConfig?.UserInfo;

    const companyId = accountConfig?.CompanyInfo?.id;
    if (!isNullorUndefined(companyId)) {
      requestObject.Account_Name = companyId;
    } else showAlert(Company_Record_Not_Found_Message, "error");

    if (isNullorUndefined(userInfo)) {
      await ProcessApiRequest(
        accessToken.access_token,
        ApiConfig.Action_Contacts,
        requestObject
      )
        .then(function (apiResponse) {
          if (
            !isNullorUndefined(apiResponse) &&
            !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
          ) {
            requestObject.id = apiResponse.data.results.id;
            let updateAccountConfig = { ...accountConfig };
            updateAccountConfig.UserInfo = requestObject;
            setAccountConfig(updateAccountConfig);
            showAlert(Record_Success_Message, "success");
            resetForm();
            setMobileNumber("");
            handleNext();
            //navigate(AGREEMENT_PAGE);
          } else {
            showAlert(Record_Failure_Message, "error");
          }
        })
        .catch(function (exception) {
          const errorResponse = exception?.response;
          const responseCode = exception?.code;
          const responseStatus = errorResponse.status;
          if (
            responseCode === ApiConfig.ERR_BAD_REQUEST &&
            responseStatus === 403
          ) {
            showAlert(User_Record_Exist_Message, "error");
          } else if (
            exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
            !isNullorUndefined(errorResponse) &&
            !isNullorUndefined(errorResponse?.data) &&
            errorResponse?.data?.error &&
            errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
          ) {
            showAlert(Token_Expired_Message, "error");
            //RequestToken();
            navigate(LOGIN_PAGE);
          } else showAlert(Record_Failure_Message, "error");
        });
    } else if (!isNullorUndefined(userInfo.id)) {
      const requestJsonObject = {
        record_Id: userInfo.id,
        data: {
          Section: ApiConfig.Action_Contacts,
          InputRequest: requestObject,
        },
      };

      await ProcessApiPutRequest(
        accessToken.access_token,
        ApiConfig.Action_Contacts,
        requestJsonObject
      )
        .then(function (apiResponse) {
          if (
            !isNullorUndefined(apiResponse) &&
            !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
          ) {
            let updateAccountConfig = { ...accountConfig };
            requestObject.id = userInfo.id;
            updateAccountConfig.UserInfo = requestObject;
            setAccountConfig(updateAccountConfig);
            showAlert(Record_Success_Message, "success");
            resetForm();
            setMobileNumber("");
            handleNext();
            //navigate(AGREEMENT_PAGE, { replace: true });
          } else {
            showAlert(Record_Failure_Message, "error");
          }
        })
        .catch(function (exception) {
          const errorResponse = exception?.response;
          if (
            exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
            !isNullorUndefined(errorResponse) &&
            !isNullorUndefined(errorResponse?.data) &&
            errorResponse?.data?.error &&
            errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
          ) {
            showAlert(Token_Expired_Message, "error");
            //RequestToken();
            navigate(LOGIN_PAGE);
          } else showAlert(Record_Failure_Message, "error");
        });
    }
    setIsLoading(false);
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Grid container spacing={1}>
          {isLoading ? (
            <LogoIndicator />
          ) : (
            <Formik
              initialValues={formValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                } = props;
                return (
                  <Box
                    sx={{ mt: 2 }}
                    ml={{
                      xs: 1,
                      sm: 5,
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                      justifyContent={"center"}
                    >
                      <Typography variant="h5" style={{ color: "#1976d2" }}>
                        User Information
                      </Typography>
                    </Grid>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 2 }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.First_Name && touched.First_Name}
                            label="First Name"
                            name="First_Name"
                            value={Formatter.NoSpecialChar(values.First_Name)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (touched.First_Name && errors.First_Name) ||
                              `${values.First_Name.length}/${CHARACTER_LIMIT}`
                            }
                            margin="dense"
                            fullWidth
                            size="small"
                            inputProps={{ maxlength: CHARACTER_LIMIT }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.Last_Name && touched.Last_Name}
                            label="Last Name"
                            name="Last_Name"
                            value={Formatter.NoSpecialChar(values.Last_Name)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (touched.Last_Name && errors.Last_Name) ||
                              `${values.Last_Name.length}/${CHARACTER_LIMIT}`
                            }
                            margin="dense"
                            fullWidth
                            size="small"
                            inputProps={{ maxlength: CHARACTER_LIMIT }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.Email && touched.Email}
                            type="Email"
                            label="Email"
                            name="Email"
                            value={values.Email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.Email && errors.Email}
                            margin="dense"
                            fullWidth
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.Mobile && touched.Mobile}
                            label="Mobile"
                            name="Mobile"
                            value={mobileNumber}
                            placeholder="123-456-7890"
                            onChange={(e) => {
                              inputChangeHandler(
                                e,
                                "Mobile",
                                handleChange,
                                setFieldValue
                              );
                            }}
                            inputProps={{ maxlength: 12 }}
                            onBlur={handleBlur}
                            helperText={touched.Mobile && errors.Mobile}
                            margin="dense"
                            fullWidth
                            size="small"
                          />
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Button
                          type="button"
                          className="outline"
                          onClick={handleReset}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button
                          type="button"
                          className="outline"
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          Next
                        </Button>
                      </DialogActions>
                    </form>
                  </Box>
                );
              }}
            </Formik>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Contact;
