import RouteModule from "./routes/routes";
import { AlertProvider } from "./customHook/useAlert";
import { UserContextProvider } from "./customHook/UserContext";
import "./styles/App.css";

function App() {
  return (
    <>
      {/* <header>
        <div className='header__content'>
          <div className='logo'>
            <strong>Sunwell Finacle</strong>
          </div>
        </div>
      </header>
      <main>
        <UserContainer />
      </main> */}
      <UserContextProvider>
        <AlertProvider>
          <RouteModule></RouteModule>
        </AlertProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
