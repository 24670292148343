import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../utility/userUtil";

// handle the public routes
const PublicRoutes = () => {
  return !getToken() ? <Outlet /> : <Navigate to="/user/" />;
};

export default PublicRoutes;
