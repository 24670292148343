export const FORGOT_PASSWORD = "forgotpassword";
export const USER_REGISTRATION = "userregistration";
export const RESET_PASSWORD = "resetpassword";
export const COMPANY_PAGE = "/company";
export const ADDRESS_PAGE = "/address";
export const ORDER_CONFIRM_PAGE = "/confirm";
export const LOGIN_PAGE = "/login";
export const USER_PAGE = "/user";
export const AGREEMENT_PAGE = "/agreement";
export const PAYMENT_PAGE = "/payment";
export const COMPLETE_PAGE = "/complete";
