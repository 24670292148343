import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Grid, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { Formik } from "formik";
import * as Yup from "yup";
import { isNullorUndefined } from "../../utility/validator";
import * as formatter from "../../utility/formatter";
import { ProcessApiPutRequest, generateToken } from "../../services/api";
import * as ApiConfig from "../../services/apiConfiguration";
import { useAlert } from "../../customHook/useAlert";
import { useUserContext } from "../../customHook/UserContext";
import StateList from "../../assets/constants/stateList.json";
import MenuItem from "@mui/material/MenuItem";
import {
  Record_Success_Message,
  Record_Failure_Message,
  Token_Expired_Message,
  Messages,
} from "../../constant/messages";
import { USA_TEXT } from "../../constant/formFields";
import { LOGIN_PAGE } from "../../constant/routeConstant";
import LogoIndicator from "../../controls/LogoIndicator";
import * as Formatter from "../../utility/formValidations";

const initialDefaultValues = {
  Street: "",
  Suite: "",
  City: "",
  State: "",
  Zip: "",
  Fax: "",
};

const validationSchema = Yup.object().shape({
  Street: Yup.string()
    .required(Messages.StreetRequired)
    .min(3, Messages.TooShort)
    .max(50, Messages.TooLong),
  Suite: Yup.string().max(10, Messages.TooLong),
  State: Yup.string().required(Messages.StateRequired),
  Zip: Yup.string().required(Messages.ZipRequired),
  City: Yup.string()
    .required(Messages.CityRequired)
    .min(2, Messages.TooShort)
    .max(30, Messages.TooLong),
});

function Address({ handleNext, handleBack }) {
  const [isLoading, setIsLoading] = useState(false);
  const showAlert = useAlert();
  const [stateVal, setStateVal] = useState("");
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialDefaultValues);
  const STREET_CHARACTER_LIMIT = 50;
  const FAX_CHARACTER_LIMIT = 14;
  const ZIP_CHARACTER_LIMIT = 5;
  const {
    apiValue: { accessToken, setAccessToken, accountConfig, setAccountConfig },
  } = useUserContext();

  useEffect(() => {
    RequestToken();
    if (!isNullorUndefined(accessToken)) {
      PrePopulateCompanyDetails();
    }
  }, []);

  const RequestToken = async () => {
    const current_time = new Date();
    if (
      isNullorUndefined(accessToken) ||
      Math.round(current_time.getTime() / 1000) > accessToken.expires_in
    ) {
      var tokenValue = await generateToken();
      setAccessToken(tokenValue);
    }
  };

  const PrePopulateCompanyDetails = async () => {
    const addressInfo = accountConfig?.AddressInfo;
    const stateObj = StateList.find(
      (st) => st.state === addressInfo.Shipping_State
    );

    if (!isNullorUndefined(addressInfo)) {
      const savedAddressInfo = {
        Street: addressInfo.Shipping_Street,
        Suite: addressInfo.Shipping_Suite,
        City: addressInfo.Shipping_City,
        State: stateObj?.stateCode,
        Zip: addressInfo.Shipping_Code,
        Shipping_Country: addressInfo.Shipping_Country,
        Fax: addressInfo.Fax,
      };
      setStateVal(stateObj?.stateCode);
      setFormValues(savedAddressInfo);
    }

    setIsLoading(false);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);

    const stateObj = StateList.find((st) => st.stateCode === values.State);

    let requestObject = {
      Shipping_Street: values.Street,
      Shipping_Suite: values.Suite,
      Shipping_City: values.City,
      Shipping_State: stateObj.state,
      Shipping_Code: values.Zip,
      Shipping_Country: USA_TEXT,
      Fax: values.Fax,
    };

    const companyId = accountConfig?.CompanyInfo?.id;

    if (!isNullorUndefined(companyId)) {
      const requestJsonObject = {
        record_Id: companyId,
        data: {
          Section: ApiConfig.Section_Address,
          InputRequest: requestObject,
        },
      };

      await ProcessApiPutRequest(
        accessToken.access_token,
        ApiConfig.Action_Accounts,
        requestJsonObject
      )
        .then(function (apiResponse) {
          if (
            !isNullorUndefined(apiResponse) &&
            !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
          ) {
            let updateAccountConfig = { ...accountConfig };
            updateAccountConfig.AddressInfo = requestObject;
            setAccountConfig(updateAccountConfig);
            showAlert(Record_Success_Message, "success");
            resetForm();
            setStateVal("");
            handleNext();
            //navigate(USER_PAGE);
          } else {
            showAlert(Record_Failure_Message, "error");
          }
        })
        .catch(function (exception) {
          const errorResponse = exception?.response;
          if (
            exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
            !isNullorUndefined(errorResponse) &&
            !isNullorUndefined(errorResponse?.data) &&
            errorResponse?.data?.error &&
            errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
          ) {
            showAlert(Token_Expired_Message, "error");
            //RequestToken();
            navigate(LOGIN_PAGE);
          } else {
            showAlert(Record_Failure_Message, "error");
            resetForm();
            setStateVal("");
          }
        });
    } else showAlert("Associated Company Details not found", "error");
    setIsLoading(false);
    setSubmitting(false);
  };

  const inputChangeHandler = (event, fieldId, handleChange, setFieldValue) => {
    var targetVal = event.target.value;
    if (fieldId === "State") {
      setStateVal(targetVal);
      setFieldValue(fieldId, targetVal);
    }
    handleChange(event);
  };

  return (
    <React.Fragment>
      <Box>
        <Grid container spacing={1}>
          {isLoading ? (
            <LogoIndicator />
          ) : (
            <Formik
              initialValues={formValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  handleReset,
                } = props;
                return (
                  <Box
                    sx={{ mt: 2 }}
                    ml={{
                      xs: 1,
                      sm: 5,
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                      justifyContent={"center"}
                    >
                      <Typography variant="h5" style={{ color: "#1976d2" }}>
                        Address Information
                      </Typography>
                    </Grid>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 2 }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.Street && touched.Street}
                            label="Street"
                            name="Street"
                            value={values.Street}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (touched.Street && errors.Street) ||
                              `${values.Street.length}/${STREET_CHARACTER_LIMIT}`
                            }
                            margin="dense"
                            fullWidth
                            size="small"
                            inputProps={{ maxlength: STREET_CHARACTER_LIMIT }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.Suite && touched.Suite}
                            label="Suite/Apt#"
                            name="Suite"
                            value={values.Suite}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.Suite && errors.Suite}
                            margin="dense"
                            fullWidth
                            size="small"
                            inputProps={{ maxlength: 10 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            select
                            label="State"
                            name="State"
                            margin="dense"
                            fullWidth
                            size="small"
                            value={stateVal}
                            onChange={(e) => {
                              inputChangeHandler(
                                e,
                                "State",
                                handleChange,
                                setFieldValue
                              );
                            }}
                            onBlur={handleBlur}
                            helperText={touched.State && errors.State}
                            error={errors.State && touched.State}
                            disabled={values?.business_address}
                          >
                            {StateList.map((st) => (
                              <MenuItem key={st.state} value={st.stateCode}>
                                {st.state}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.City && touched.City}
                            label="City"
                            name="City"
                            value={Formatter.NoSpecialChar(values.City)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (touched.City && errors.City) ||
                              `${values.City.length}/${STREET_CHARACTER_LIMIT}`
                            }
                            margin="dense"
                            fullWidth
                            size="small"
                            inputProps={{ maxlength: STREET_CHARACTER_LIMIT }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.Zip && touched.Zip}
                            label="Zip"
                            name="Zip"
                            value={formatter.formatNumber(values.Zip)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (touched.Zip && errors.Zip) ||
                              `${
                                formatter.formatNumber(values.Zip).length
                              }/${ZIP_CHARACTER_LIMIT}`
                            }
                            margin="dense"
                            fullWidth
                            size="small"
                            inputProps={{ maxlength: ZIP_CHARACTER_LIMIT }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={errors.Fax && touched.Fax}
                            label="Fax"
                            name="Fax"
                            value={formatter.formatFax(values.Fax)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (touched.Fax && errors.Fax) ||
                              `${
                                formatter.formatFax(values.Fax).length
                              }/${FAX_CHARACTER_LIMIT}`
                            }
                            margin="dense"
                            fullWidth
                            size="small"
                            inputProps={{ maxlength: FAX_CHARACTER_LIMIT }}
                          />
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Button
                          type="button"
                          className="outline"
                          onClick={handleReset}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button
                          type="button"
                          className="outline"
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          Next
                        </Button>
                      </DialogActions>
                    </form>
                  </Box>
                );
              }}
            </Formik>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}
export default Address;
