export const USA_TEXT = "United States";

export const TextBoxLong = "Field Basic Long";

export const DatePicker = "Field - Calendar-Jquery";

export const RadioSelection = "SelectionTextType";

export const TribeDropdown = "Tribe Select Dropdown";
export const DropdownKeyValueOptions = "DropdownKVP";

export const Dropdown = "Dropdown";
export const LabelField = "Label";

export const ButtonField = "Button";

export const ContinueText = "Continue";

export const OptOutText = "OptOut";

export const NextText = "Next Page";
export const SubmitForm = "Submit Survey";

export const MySpouseTxt = "Spouse";
export const labelDob = "VoterDob";
export const labelSsn = "VoterSsn";

export const MySpouseFieldId = 22421; // export constFirstNameFieldId - 943; //export constLastNameFieldId 946;

export const MilitaryStatusFieldId = 931;
export const FullNameFieldId = 1137;

export const MilitaryStatus_CurrentlyEnlistedValueId = 1633;

export const StateFields = [924, 928, 937, 1368, 11383];
export const nasThankFieldId = 11386;
export const nativeAmericanHeaderTxt = "NativeAmericanLabel";

export const nativeAmericanTribeTxt = "NativeAmericanTribe";

export const naSpouseTxt = "NativeAmericanSpouse";
export const LognTermUnEmployTxt = "LongTermUnemployed";

export const nasThankFieldText = "ThankYouMessage";
export const skipSurveyFieldId = "NoNeedToSurveyMessage";
export const redirectUrlFieldId = "Redirect URL";

export const ConfirmationCopyTxt = "ConfirmationCopyText";
export const ConfirmationCopyMessageTxt = "ConfirmationCopyMessage";

export const NameConfirmationFieldId = "NameConfirmation";
export const MilitaryStatusFieldTxt = "USArmedForcesStatus";

export const MilitaryStatus_EnlistedTxt = "CurrentlyEnlisted";
export const MilitaryStatus_EnlistedDate = "USArmed Forces EnlistDate";

export const MilitaryStatusDischargeDate = "USArmedForcesDischargeDate";

export const nativeAmericanTribeMenTxt = "Native American TribeMember";

export const SKIP_SURVEY_TXT = "SkipSurvey";
export const REDIRECT_URL_TXT = "RedirectUrl";

export const SHORT_NAME_TXT = "Shortname";

export const TPC_URL = "tpuric";
