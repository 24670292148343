import React from "react";
import loadingImg from "../assets/image/loading.gif";

const Loader = ({ message }) => {
  return (
    <div>
      <img alt="" src={loadingImg} />
      <div> {message} </div>
    </div>
  );
};

export default Loader;
