import axios from "axios";
import {
  GET_TOKEN,
  Action_Logon,
  Action_Payment,
  Action_SignUp,
  Action_AccountRecord,
  Action_User_Record,
  LOGON_TOKEN,
  Action_User_Register,
  Action_SignUp_V1,
  Action_SaveUser_Record,
} from "./apiConfiguration";
import { isNullorUndefined } from "../utility/validator";
import { ConfigProvider } from "../config/Provider";

export const ProcessApiRequest = async (
  accessToken,
  apiEndPoint,
  inputRequest
) => {
  const Service_Url = ConfigProvider().API_URL + "record";

  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Method: apiEndPoint,
    Bearer: accessToken,
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const ProcessApiPutRequest = async (
  accessToken,
  apiEndPoint,
  inputRequest
) => {
  const Service_Url = ConfigProvider().API_URL + "record";

  const headers = {
    "Access-Control-Allow-Methods": "Put",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Method: apiEndPoint,
    Bearer: accessToken,
  };

  return await axios.put(Service_Url, inputRequest, {
    headers,
  });
};

export const ProcessApiGetRequest = async (
  accessToken,
  apiEndPoint,
  qsParams,
  inputRequest
) => {
  let Service_Url = ConfigProvider().API_URL + "record";

  if (!isNullorUndefined(qsParams)) Service_Url = Service_Url + "?" + qsParams;

  const headers = {
    "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Method: apiEndPoint,
    Bearer: accessToken,
  };

  return await axios.get(Service_Url, { headers });
};

export const generateToken = async () => {
  const Service_Url = ConfigProvider().API_URL + GET_TOKEN;

  const headers = {
    headers: {
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "*",
    },
  };

  const response = await axios.get(Service_Url, {
    headers,
  });

  const access_token = response.data.access_token;
  const current_time = new Date();
  current_time.setMinutes(current_time.getMinutes() + 59);

  var authConfig = {
    access_token,
    expires_in: Math.round(current_time.getTime() / 1000),
  };

  return authConfig;
};

export const LogonApiRequest = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_Logon;

  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const SignUpApiRequest = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_SignUp;

  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Bearer: LOGON_TOKEN,
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const CheckUser = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_User_Record;

  const headers = {
    "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const UpdateLoginUser = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_SaveUser_Record;

  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const insertPaymentApi = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_Payment;
  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };
  return await axios.post(Service_Url, inputRequest, { headers });
};

export const AccountDetailsApi = async (accessToken, qsParams) => {
  const Service_Url =
    ConfigProvider().API_URL + Action_AccountRecord + "?" + qsParams;

  const headers = {
    "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Bearer: accessToken,
  };

  return await axios.get(Service_Url, { headers });
};

export const ParallelApiCalls = async (requestObj1, requestObj2) => {
  axios
    .all([requestObj1, requestObj2])
    .then(
      axios.spread((...responses) => {
        // const responseOne = responses[0];
        // const responseTwo = responses[1];
        // use/access the results
      })
    )
    .catch((errors) => {
      console.log(errors);
    });
};

export const registerUser = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_User_Register;

  const headers = {
    "Access-Control-Allow-Methods": "GET",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};

export const SignUpApiV1Request = async (inputRequest) => {
  const Service_Url = ConfigProvider().API_URL + Action_SignUp_V1;

  const headers = {
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Bearer: LOGON_TOKEN,
  };

  return await axios.post(Service_Url, inputRequest, {
    headers,
  });
};
