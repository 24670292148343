/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Grid, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import { Formik } from "formik";
import * as Yup from "yup";
import { isNullorUndefined } from "../../utility/validator";
import * as ApiConfig from "../../services/apiConfiguration";
import { ProcessApiRequest, generateToken } from "../../services/api";
import { useUserContext } from "../../customHook/UserContext";
import { useAlert } from "../../customHook/useAlert";
import {
  Record_Success_Message,
  Record_Failure_Message,
  Token_Expired_Message,
  Lead_Record_Exist_Message,
  Messages
} from "../../constant/messages";
import { LOGIN_PAGE } from "../../constant/routeConstant";
import LeadSourceList from "../../assets/constants/leadsouceTypes.json";
import { phoneNumberAutoFormat } from "../../utility/formValidations";
import LogoIndicator from "../../controls/LogoIndicator";

const validationSchema = Yup.object().shape({
  FirstName: Yup.string()
    .min(3, Messages.TooShort)
    .max(30, Messages.TooLong),
    Website: Yup.string()
    .max(50, Messages.TooLong),
  LastName: Yup.string()
    .required(Messages.LastNameRequired)
    .min(3, Messages.TooShort)
    .max(30, Messages.TooLong),
  CompanyName: Yup.string()
    .required(Messages.CompanyNameRequired)
    .max(30, Messages.TooLong),
  Email: Yup.string()
    .email(Messages.InvalidEmailMessage)
    .min(3, Messages.TooShort)
    .max(30, Messages.TooLong),
});

var initialDefaultValues = {
  FirstName: "",
  LastName: "",
  CompanyName: "",
  Leadsource: "",
  Website: "",
  Email: "",
  Phone: "",
  Notes: "",
};

function Leads() {
  const CHARACTER_LIMIT = 50;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const showAlert = useAlert();
  const [leadSourceId, setLeadSourceId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formValues, setFormValues] = useState(initialDefaultValues);

  const {
    apiValue: { accessToken, setAccessToken, accountConfig, setAccountConfig },
  } = useUserContext();

  useEffect(() => {
    setIsLoading(true);
    RequestToken();
    setIsLoading(false);
  }, [accessToken]);

  const RequestToken = async () => {
    const current_time = new Date();
    if (
      isNullorUndefined(accessToken) ||
      Math.round(current_time.getTime() / 1000) > accessToken.expires_in
    ) {
      var tokenValue = await generateToken();
      setAccessToken(tokenValue);
    }
  };

  const inputChangeHandler = (event, fieldId, handleChange, setFieldValue) => {
    //var targetKey = event.target.id;
    var targetVal = event.target.value;

    if (fieldId === "Leadsource") {
      setLeadSourceId(targetVal);
      setFieldValue(fieldId, targetVal);
    }
    else if (fieldId === "Phone") {
      const formattedValue = phoneNumberAutoFormat(targetVal);
      setPhoneNumber(formattedValue);
      setFieldValue(fieldId, targetVal);
    }

    handleChange(event);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setFormValues(values);
    let leadSourceName = values.Leadsource ?  LeadSourceList?.find(x=>x.code === leadSourceId).leadSourceName  : "";
    const leadRequestObj = {
      First_Name: values.FirstName,
      Last_Name: values.LastName,
      Company: values.CompanyName,
      Lead_Source: leadSourceName,
      Website: values.Website,
      Email: values.Email,
      Description: values.Notes,
      Phone: values.Phone?.replaceAll('-', '')
    };

    await ProcessApiRequest(
      accessToken.access_token,
      ApiConfig.Action_Leads,
      leadRequestObj
    )
      .then(function (apiResponse) {
        if (
          !isNullorUndefined(apiResponse) &&
          !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
        ) {
          const leadId = apiResponse.data.results.id;
          leadRequestObj.id = leadId;
          let updateAccountConfig = { ...accountConfig };
          updateAccountConfig.LeadInfo = leadRequestObj;

          setAccountConfig(updateAccountConfig);
          showAlert(Record_Success_Message, "success");
          resetForm();
          setLeadSourceId("");
          setPhoneNumber("");
        } else {
          showAlert(Record_Failure_Message, "error");
        }
      })
      .catch(function (exception) {
        const errorResponse = exception?.response;
        const responseCode = exception?.code;
        const responseStatus = errorResponse.status;
        if (
            responseCode === ApiConfig.ERR_BAD_REQUEST &&
            responseStatus === 403
          ) {
            showAlert(Lead_Record_Exist_Message, "error");
          } else if (
          exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
          !isNullorUndefined(errorResponse) &&
          !isNullorUndefined(errorResponse?.data) &&
          errorResponse?.data?.error &&
          errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
        ) {
          showAlert(Token_Expired_Message, "error");
          navigate(LOGIN_PAGE);
        } else showAlert(Record_Failure_Message, "error");
      });

    setSubmitting(false);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        {isLoading ? (
          <LogoIndicator />
        ) : (
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              dirty,
              touched,
              values,
              handleReset,
              setFieldValue,
              isSubmitting,
            }) => (
              <Box
                sx={{ mt: 2 }}
                ml={{
                  xs: 1,
                  sm: 5,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ mt: 2, mb: 2, textAlign: "center" }}
                  justifyContent={"center"}
                >
                  <Typography variant="h5" style={{ color: "#1976d2" }}>
                    Lead Information
                  </Typography>
                </Grid>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2 }}
                  >
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        error={errors.FirstName && touched.FirstName}
                        label="First Name"
                        name="FirstName"
                        value={values.FirstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.FirstName && touched.FirstName && errors.FirstName
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                        inputProps={{ maxlength: CHARACTER_LIMIT }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        error={errors.LastName && touched.LastName}
                        label="Last Name"
                        name="LastName"
                        value={values.LastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.LastName && touched.LastName && errors.LastName
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                        inputProps={{ maxlength: CHARACTER_LIMIT }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        error={errors.CompanyName && touched.CompanyName}
                        label="Company"
                        name="CompanyName"
                        value={values.CompanyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.CompanyName &&
                          touched.CompanyName &&
                          errors.CompanyName
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                        inputProps={{ maxlength: CHARACTER_LIMIT }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        margin="dense"
                        fullWidth
                        size="small"
                        id="Leadsource"
                        name="Leadsource"
                        value= {leadSourceId}
                        label="Lead Source"
                        onChange={(e) => {
                          inputChangeHandler(
                            e,
                            "Leadsource",
                            handleChange,
                            setFieldValue
                          );
                        }}
                        onBlur={handleBlur}
                        helperText={touched.Leadsource && errors.Leadsource}
                        error={errors.Leadsource && touched.Leadsource}
                      >
                        {LeadSourceList.map((btItem) => {
                          return (
                            <MenuItem key={btItem.code} value={btItem.code}>
                              {btItem.leadSourceName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={errors.Website && touched.Website}
                        label="Web Site"
                        name="Website"
                        id="Website"
                        value={values.Website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.Website && touched.Website && errors.Website
                        }
                        inputProps={{ maxlength: 50 }}
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={errors.Email && touched.Email}
                        label="Email"
                        name="Email"
                        type="email"
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.Email && touched.Email && errors.Email
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Phone"
                        name="Phone"
                        id="Phone"
                        inputProps={{ maxlength: 12 }}
                        value={phoneNumber}
                        placeholder="123-456-7890"
                        onChange={(e) => {
                          inputChangeHandler(
                            e,
                            "Phone",
                            handleChange,
                            setFieldValue
                          );
                        }}
                        onBlur={handleBlur}
                        helperText={
                          errors.Phone && touched.Phone && errors.Phone
                        }
                        error={errors.Phone && touched.Phone}
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Notes"
                        name="Notes"
                        multiline
                        rows={2}
                        value={values.Notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.Notes && touched.Notes && errors.Notes
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </DialogActions>
                </form>
              </Box>
            )}
          </Formik>
        )}
      </Grid>
    </Box>
  );
}

export default Leads;
