import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../utility/userUtil";
import Layout from "../components/Common/Layout";
// handle the private routes
const PrivateRoutes = () => {
  const hasToken = getToken();

  return hasToken ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
