import { useEffect } from "react";
//import Sidebar from "./Sidebar";
import { getUser } from "../../utility/userUtil";
import AppBar from "./AppBar";
import Footer from "./Footer";
import Header from "./Header";
import "../../assets/css/user1.css";

const Layout = ({ children }) => {
  const user = getUser();

  useEffect(() => {
    const head = document.head;
    const link = document.createElement("style");
    link.src = `../../assets/css/${user.cssFileName}`;
    head.appendChild(link);
  }, []);

  return (
    <div>
      <Header />
      {/* <Sidebar /> */}
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
