export function getPDFWorkerSrc(pdfVersion) {
  return `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfVersion}/pdf.worker.js`;
}

export function getAgreementUrl(accountId, contactId, productId) {
  let documentUrl;
  //TODO :- Remove this condition for TESTING ONLY
  if (
    accountId === undefined ||
    contactId === undefined ||
    productId === undefined
  ) {
    documentUrl =
      "https://creatorapp.zohopublic.com/krishnatek/test/page-perma/Zoho_Sign_Embedded_Page/zAnMPhs4bWB5QPCNuPxhh2QnTGJy6KCUtMgJQv4xE1C79grWhYQOBr9q3kDAP0mSq8ZkUjx4Uq1KyCusYduVt4NyhMj28WxVEdYU?account_crm_id=6133181000000602011&contact_crm_id=6133181000000598002&product_crm_id=6133181000000840001";
  } else
    documentUrl = `https://creatorapp.zohopublic.com/krishnatek/test/page-perma/Zoho_Sign_Embedded_Page/zAnMPhs4bWB5QPCNuPxhh2QnTGJy6KCUtMgJQv4xE1C79grWhYQOBr9q3kDAP0mSq8ZkUjx4Uq1KyCusYduVt4NyhMj28WxVEdYU?account_crm_id=${accountId}&contact_crm_id=${contactId}&product_crm_id=${productId}`;
  return documentUrl;
}
