import InvoiceComponent from "../components/invoice/index";

const InvoiceContainer = () => {
  return (
    <div>
      <InvoiceComponent> </InvoiceComponent>
    </div>
  );
};
export default InvoiceContainer;
