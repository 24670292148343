import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { Grid, Paper, Box } from "@mui/material";
import { isNullorUndefined } from "../../utility/validator";
import { Confirmation_Message } from "../../constant/messages";

function ConfirmationPage() {
  const location = useLocation();
  const confirmationNo = location.state.confirmationNo;
  const message = Confirmation_Message;
  const copyButtonValue = "Copy Confirmation";
  const confirmationText = null;
  const [copyState, SetCopyState] = useState(copyButtonValue);

  const handleClick = () => {
    SetCopyState("Copied");
  };

  return (
    <Grid container spacing={1} direction="column" alignItems="center">
      <Paper
        style={{ minHeight: "450px" }}
        sx={{ mt: 10, textAlign: "center" }}
        elevation={4}
        square={false}
      >
        <Box
          px={{
            xs: 0,
            sm: 5,
          }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{ mt: 10, mb: 2, textAlign: "center" }}
            justifyContent={"center"}
          >
            <div>{message}</div>

            {!isNullorUndefined(confirmationNo) ? (
              <Grid
                container
                xs={12}
                sx={{ mt: 10, textAlign: "center" }}
                justifyContent={"center"}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigator.clipboard.writeText(confirmationNo);
                    handleClick();
                  }}
                >
                  {copyState}
                </Button>

                <div className="centered-row-container">
                  <div>{confirmationText}</div>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
}

export default ConfirmationPage;
