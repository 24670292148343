/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Grid, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import { Formik } from "formik";
import * as Yup from "yup";
import { isNullorUndefined } from "../../utility/validator";
import * as ApiConfig from "../../services/apiConfiguration";
import {
  ProcessApiRequest,
  generateToken,
  AccountDetailsApi,
  ProcessApiPutRequest,
} from "../../services/api";
import { useUserContext } from "../../customHook/UserContext";
import { useAlert } from "../../customHook/useAlert";
import {
  Record_Success_Message,
  Record_Failure_Message,
  Company_Record_Exist_Message,
  Token_Expired_Message,
} from "../../constant/messages";
import { LOGIN_PAGE } from "../../constant/routeConstant";
import BusinessTypeList from "../../assets/constants/businessTypes.json";
import * as Formatter from "../../utility/formValidations";
import { getUser } from "../../utility/userUtil";
import LogoIndicator from "../../controls/LogoIndicator";

const validationSchema = Yup.object().shape({
  CompanyName: Yup.string()
    .required("Required")
    .min(3, "Too Short!")
    .max(70, "Too Long!"),
  Email: Yup.string()
    .required("Required")
    .min(3, "Too Short!")
    .max(30, "Too Long!"),
  Phone: Yup.string().required("Required"),
  EINnumber: Yup.string().required("Required"),
});

var initialDefaultValues = {
  CompanyName: "",
  CompanyDba: "",
  Notes: "",
  Email: "",
  EINnumber: "",
  BusinessType: "",
  Phone: "",
};

function Company({ handleNext }) {
  const CHARACTER_LIMIT = 50;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const showAlert = useAlert();
  const [businessId, setBusinessId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [einNumber, setEinNumber] = useState("");
  const [formValues, setFormValues] = useState(initialDefaultValues);

  const {
    apiValue: { accessToken, setAccessToken, accountConfig, setAccountConfig },
  } = useUserContext();

  useEffect(() => {
    setIsLoading(true);
    RequestToken();
    if (!isNullorUndefined(accessToken)) {
      PrePopulateCompanyDetails();
    }
  }, [accessToken]);

  const RequestToken = async () => {
    const current_time = new Date();

    if (
      isNullorUndefined(accessToken) ||
      Math.round(current_time.getTime() / 1000) > accessToken.expires_in
    ) {
      var tokenValue = await generateToken();
      setAccessToken(tokenValue);
    }
  };

  const PrePopulateCompanyDetails = async () => {
    const companyId = accountConfig?.CompanyInfo?.id;

    if (isNullorUndefined(companyId)) {
      const userInfo = getUser();
      const qaParam = `record_Id=${userInfo.userId}`;

      await AccountDetailsApi(accessToken.access_token, qaParam)
        .then(function (apiResponse) {
          if (
            !isNullorUndefined(apiResponse) &&
            !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
          ) {
            const responseData = apiResponse.data;
            const responseResult = responseData?.results?.data;
            setAccountConfig(responseResult);

            const companyData = responseResult?.CompanyInfo;

            const einFormat = isNullorUndefined(companyData.Ein_Number)
              ? ""
              : Formatter.EinNumberAutoFormat(companyData.Ein_Number);
            const phoneFormat = isNullorUndefined(companyData.Phone)
              ? ""
              : Formatter.phoneNumberAutoFormat(companyData.Phone);

            const businessTypeObj = BusinessTypeList.find(
              (bt) => bt.businessName === companyData.Business_Type
            );
            const businessTypeCode = businessTypeObj?.code;

            const initialCompInfo = {
              CompanyName: companyData.Account_Name,
              CompanyDba: companyData.Company_DBA,
              Notes: companyData.Description,
              Email: companyData.Company_Email,
              EINnumber: einFormat,
              BusinessType: businessTypeCode,
              Phone: phoneFormat,
            };

            setBusinessId(businessTypeCode);
            setPhoneNumber(phoneFormat);
            setEinNumber(einFormat);
            setFormValues(initialCompInfo);
          } else {
            showAlert("Company lead details not available!", "warning");
          }
        })
        .catch(function (error) {
          console.log(error);
          showAlert("Something went wrong. Please try again later.", "error");
          //showBoundary(error);
        });
    } else {
      const compnayInfo = accountConfig?.CompanyInfo;
      const einFormat = isNullorUndefined(compnayInfo.Ein_Number)
        ? ""
        : Formatter.EinNumberAutoFormat(compnayInfo.Ein_Number);
      const phoneFormat = isNullorUndefined(compnayInfo.Phone)
        ? ""
        : Formatter.phoneNumberAutoFormat(compnayInfo.Phone);

      const businessTypeObj = BusinessTypeList.find(
        (bt) => bt.businessName === compnayInfo.Business_Type
      );

      const businessTypeCode = businessTypeObj?.code;

      const savedCompInfo = {
        CompanyName: compnayInfo.Account_Name,
        CompanyDba: compnayInfo.Company_DBA,
        Notes: compnayInfo.Description,
        Email: compnayInfo.Company_Email,
        EINnumber: einFormat,
        BusinessType: businessTypeCode,
        Phone: phoneFormat,
      };

      setBusinessId(businessTypeCode);
      setPhoneNumber(phoneFormat);
      setEinNumber(einFormat);
      setFormValues(savedCompInfo);
    }

    setIsLoading(false);
  };

  const inputChangeHandler = (event, fieldId, handleChange, setFieldValue) => {
    //var targetKey = event.target.id;
    var targetVal = event.target.value;

    if (fieldId === "BusinessType") {
      setBusinessId(targetVal);
      setFieldValue(fieldId, targetVal);
    }

    if (fieldId === "EINnumber") {
      const formattedValue = Formatter.EinNumberAutoFormat(targetVal);
      setEinNumber(formattedValue);
      setFieldValue(fieldId, targetVal);
    }

    if (fieldId === "Phone") {
      const formattedValue = Formatter.phoneNumberAutoFormat(targetVal);
      setPhoneNumber(formattedValue);
      setFieldValue(fieldId, targetVal);
    }
    handleChange(event);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const companyId = accountConfig?.CompanyInfo?.id;
    const loggedInUser = getUser();
    setIsLoading(true);
    const businessTypeObj = BusinessTypeList.find(
      (bt) => bt.code === values.BusinessType
    );

    setFormValues(values);

    const companyRequestObj = {
      Account_Name: values.CompanyName,
      Company_DBA: values.CompanyDba,
      Ein_Number: Formatter.EinNumberAutoFormat(values.EINnumber),
      Business_Type: businessTypeObj?.businessName,
      Description: values.Notes,
      Phone: values.Phone,
      Company_Email: values.Email,
      Email: loggedInUser.userId,
    };

    if (isNullorUndefined(companyId)) {
      await ProcessApiRequest(
        accessToken.access_token,
        ApiConfig.Action_Accounts,
        companyRequestObj
      )
        .then(function (apiResponse) {
          if (
            !isNullorUndefined(apiResponse) &&
            !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
          ) {
            const accountId = apiResponse.data.results.id;
            companyRequestObj.id = accountId;
            let updateAccountConfig = { ...accountConfig };
            updateAccountConfig.CompanyInfo = companyRequestObj;

            setAccountConfig(updateAccountConfig);
            showAlert(Record_Success_Message, "success");
            resetForm();
            setBusinessId("");
            setPhoneNumber("");
            setEinNumber("");
            handleNext();
            //navigate(ADDRESS_PAGE, { replace: true });
          } else {
            showAlert(Record_Failure_Message, "error");
          }
        })
        .catch(function (exception) {
          const errorResponse = exception?.response;
          const responseCode = exception?.code;
          const responseStatus = errorResponse.status;
          if (
            responseCode === ApiConfig.ERR_BAD_REQUEST &&
            responseStatus === 403
          ) {
            showAlert(Company_Record_Exist_Message, "error");
          } else if (
            exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
            !isNullorUndefined(errorResponse) &&
            !isNullorUndefined(errorResponse?.data) &&
            errorResponse?.data?.error &&
            errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
          ) {
            showAlert(Token_Expired_Message, "error");
            //RequestToken();
            navigate(LOGIN_PAGE);
          } else showAlert(Record_Failure_Message, "error");
        });
    } else {
      if (!isNullorUndefined(companyId)) {
        const requestJsonObject = {
          record_Id: companyId,
          data: {
            Section: ApiConfig.Section_Company,
            InputRequest: companyRequestObj,
          },
        };

        await ProcessApiPutRequest(
          accessToken.access_token,
          ApiConfig.Action_Accounts,
          requestJsonObject
        )
          .then(function (apiResponse) {
            if (
              !isNullorUndefined(apiResponse) &&
              !isNullorUndefined(apiResponse.data) & !apiResponse.data.error
            ) {
              let updateAccountConfig = { ...accountConfig };
              updateAccountConfig.CompanyInfo = companyRequestObj;
              companyRequestObj.id = companyId;

              setAccountConfig(updateAccountConfig);
              showAlert(Record_Success_Message, "success");
              resetForm();
              setBusinessId("");
              setPhoneNumber("");
              setEinNumber("");
              handleNext();
              //navigate(ADDRESS_PAGE, { replace: true });
            } else {
              showAlert(Record_Failure_Message, "error");
            }
          })
          .catch(function (exception) {
            const errorResponse = exception?.response;
            const responseCode = exception?.code;
            const responseStatus = errorResponse.status;
            if (
              responseCode === ApiConfig.ERR_BAD_REQUEST &&
              responseStatus === 403
            ) {
              showAlert(Company_Record_Exist_Message, "error");
            } else if (
              exception?.code === ApiConfig.ERR_BAD_RESPONSE &&
              !isNullorUndefined(errorResponse) &&
              !isNullorUndefined(errorResponse?.data) &&
              errorResponse?.data?.error &&
              errorResponse?.data?.message === ApiConfig.INVALID_TOKEN
            ) {
              showAlert(Token_Expired_Message, "error");
              //RequestToken();
              navigate(LOGIN_PAGE);
            } else showAlert(Record_Failure_Message, "error");
          });
      }
    }

    setSubmitting(false);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        {isLoading ? (
          <LogoIndicator />
        ) : (
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              dirty,
              touched,
              values,
              handleReset,
              setFieldValue,
              isSubmitting,
            }) => (
              <Box
                sx={{ mt: 2 }}
                ml={{
                  xs: 1,
                  sm: 5,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ mt: 2, mb: 2, textAlign: "center" }}
                  justifyContent={"center"}
                >
                  <Typography variant="h5" style={{ color: "#1976d2" }}>
                    Company Information
                  </Typography>
                </Grid>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2 }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={errors.CompanyName && touched.CompanyName}
                        label="Legal Name of Company"
                        name="CompanyName"
                        value={Formatter.NoSpecialChar(values.CompanyName)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.CompanyName &&
                          touched.CompanyName &&
                          errors.CompanyName
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                        inputProps={{ maxlength: CHARACTER_LIMIT }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={errors.CompanyDba && touched.CompanyDba}
                        label="Company Dba"
                        name="CompanyDba"
                        value={Formatter.NoSpecialChar(values.CompanyDba)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.CompanyDba &&
                          touched.CompanyDba &&
                          errors.CompanyDba
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                        inputProps={{ maxlength: CHARACTER_LIMIT }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={errors.EINnumber && touched.EINnumber}
                        label="EIN Number"
                        name="EINnumber"
                        id="EINnumber"
                        value={einNumber}
                        placeholder="12-3456789"
                        onChange={(e) => {
                          inputChangeHandler(
                            e,
                            "EINnumber",
                            handleChange,
                            setFieldValue
                          );
                        }}
                        onBlur={handleBlur}
                        helperText={
                          (errors.EINnumber &&
                            touched.EINnumber &&
                            errors.EINnumber) ||
                          `${values.EINnumber.length}/9`
                        }
                        inputProps={{ maxlength: 10 }}
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        select
                        margin="dense"
                        fullWidth
                        size="small"
                        id="BusinessType"
                        name="BusinessType"
                        value={businessId}
                        label="Business Type"
                        onChange={(e) => {
                          inputChangeHandler(
                            e,
                            "BusinessType",
                            handleChange,
                            setFieldValue
                          );
                        }}
                        onBlur={handleBlur}
                        helperText={touched.BusinessType && errors.BusinessType}
                        error={errors.BusinessType && touched.BusinessType}
                      >
                        {BusinessTypeList.map((btItem) => {
                          return (
                            <MenuItem key={btItem.code} value={btItem.code}>
                              {btItem.businessName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={errors.Email && touched.Email}
                        label="Email"
                        name="Email"
                        type="email"
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.Email && touched.Email && errors.Email
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Phone"
                        name="Phone"
                        id="Phone"
                        inputProps={{ maxlength: 12 }}
                        value={phoneNumber}
                        placeholder="123-456-7890"
                        onChange={(e) => {
                          inputChangeHandler(
                            e,
                            "Phone",
                            handleChange,
                            setFieldValue
                          );
                        }}
                        onBlur={handleBlur}
                        helperText={
                          errors.Phone && touched.Phone && errors.Phone
                        }
                        error={errors.Phone && touched.Phone}
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Notes"
                        name="Notes"
                        multiline
                        rows={2}
                        value={values.Notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.Notes && touched.Notes && errors.Notes
                        }
                        margin="dense"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                    >
                      Reset
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                      Next
                    </Button>
                  </DialogActions>
                </form>
              </Box>
            )}
          </Formik>
        )}
      </Grid>
    </Box>
  );
}

export default Company;
