//import User from "../components/user/signUp";
import AddressComponent from "../components/company/address-component";

export const CompanyAddressContainer = () => {

    return (
        <div>
            <AddressComponent> </AddressComponent>
        </div>
    );
};


export default CompanyAddressContainer;