import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
//import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from "@mui/icons-material/Business";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import AddressIcon from "@mui/icons-material/PostAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
//import BarChartIcon from '@mui/icons-material/BarChart';
//import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from "@mui/icons-material/Assignment";
import PaymentIcon from "@mui/icons-material/Payment";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Link from "@mui/material/Link";
import * as ROUTE_CONSTANT from "../../constant/routeConstant";

export const mainListItems = (
  <React.Fragment>
    <ListItemButton component={Link} href={ROUTE_CONSTANT.COMPANY_PAGE}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Company" />
    </ListItemButton>
    <ListItemButton component={Link} href={ROUTE_CONSTANT.ADDRESS_PAGE}>
      <ListItemIcon>
        <AddressIcon />
      </ListItemIcon>
      <ListItemText primary="Address" />
    </ListItemButton>
    <ListItemButton component={Link} href="/user">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="User" />
    </ListItemButton>
    <ListItemButton
      component={Link}
      href="/agreement"
      // href={getAgreementUrl(null, null)}
      // rel="noopener noreferrer"
      // target="_blank"
    >
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Agreement" />
    </ListItemButton>
    <ListItemButton component={Link} href="/payment">
      <ListItemIcon>
        <PaymentIcon />
      </ListItemIcon>
      <ListItemText primary="Payment" />
    </ListItemButton>
    <ListItemButton component={Link} href="confirm">
      <ListItemIcon>
        <ReceiptIcon />
      </ListItemIcon>
      <ListItemText primary="Order Confirmation" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved Quotes
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <RequestQuoteIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);

export function getAgreementUrl(accountId, contactId) {
  const documentUrl =
    "https://creatorapp.zohopublic.com/krishnatek/test/page-perma/Zoho_Sign_Embedded_Page/zAnMPhs4bWB5QPCNuPxhh2QnTGJy6KCUtMgJQv4xE1C79grWhYQOBr9q3kDAP0mSq8ZkUjx4Uq1KyCusYduVt4NyhMj28WxVEdYU?account_crm_id=6133181000000640002&contact_crm_id=6133181000000629006";
  // const documentUrl =
  //   "https://sign.zoho.com/zsguest?locale=en&sign_id=234b4d535f495623cda1724a3b958f79f41a86300b2bee802c21c017612491c7c5c4a2a41a677a47bebf6071cdb6b2e8cb26b93e6092e5e9d6cf944ddfbf54ed8a7afbae68f5ce77e8fa3d0ba5bf137c0ac2b5f0922b0d67ca43ff5af120391f35d2601ed3d4c2c2d6cce6bb5b1b17172c407037a02de2c27ad50e77b02313747c6e7cd9b1ff2490";
  // //const documentUrl =`https://creatorapp.zohopublic.com/krishnatek/test/page-embed/Zoho_Sign_Embedded_Page/9rns1nWGFCseegrOnEgtmFbxnf21RBMd71YMu31SpyqQZvZ2xXtPhuKKmO1dZBhdut6v0tGWFOPmekhYx7pAuwXwa6qHqUuD1VnN?account_crm_id=${accountId}&contact_crm_id=${contactId}`;
  return documentUrl;
}
