
import PaymentForm from "../components/integration/integration-component";

const PaymentContainer = () => {
    return (
        <div>
            <PaymentForm> </PaymentForm>

        </div>
    )
}
export default PaymentContainer;