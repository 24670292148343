// API Configs - Url/Endpoints;
export const Enviroment = "dev";
export const NETWORK_ERROR = "ERR_NETWORK";
export const AXIOS_ERROR = "AxiosError";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const ERR_BAD_RESPONSE = "ERR_BAD_RESPONSE";
export const ERR_BAD_REQUEST = "ERR_BAD_REQUEST";

// export const API_URL = process.env.REACT_APP_API_LINK + "/api/";
export const DocuemntCorsSupportTemp = `https://cors-anywhere.herokuapp.com`;
export const IMAGE_URL = process.env.REACT_APP_API_LINK;
export const LOGON_TOKEN =
  "1000.2d0507ef22cc9011a1520705c5a6ec32.73effaa024356c2da8fcf2631b1da5c8";

// Url/Endpoints;
export const API_CONTROLLER = "elf";
export const GET_TOKEN = "getToken";
export const Action_Logon = "users/signin";
export const Action_Accounts = "Accounts";
export const Action_Contacts = "Contacts";
export const Action_SalesOrder = "Sales_Orders";
export const Action_Invoice = "Invoice";
export const Action_Payment = "payment";
export const Action_Leads = "Leads";
export const Action_SignUp = "users/signup";
export const Action_Search = "SearchRecord";
export const Action_AccountRecord = "account/record";
export const Action_Company = "Company";
export const Action_User_Record = "user/record";
export const Action_SaveUser_Record = "user/updateRecord";
export const Action_User_Register = "users/signupwithemail";
export const Action_SignUp_V1 = "users/signupv1";

// Module Section
export const Section_Payment = "Payment";
export const Section_Address = "Address";
export const Section_Company = "Company";
