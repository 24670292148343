import React, { createContext, useContext, useState, useEffect } from "react";

const getAccountState = () => {
  const accountInfo = sessionStorage.getItem("AccountConfig");
  return accountInfo ? JSON.parse(accountInfo) : null;
};

const getAccessToken = () => {
  const access_Token = sessionStorage.getItem("AccessToken");
  return access_Token ? JSON.parse(access_Token) : null;
};

const UserContext = createContext();
//context consumer hook

const useUserContext = () => {
  // get the context

  const context = useContext(UserContext);
  // if undefined, throw an error

  if (context === undefined) {
    throw new Error("useUserContext was used outside of its Provider");
  }
  return context;
};

const UserContextProvider = ({ children }) => {
  // the value that will be given to the context

  const [accessToken, setAccessToken] = useState(getAccessToken());
  const [accountConfig, setAccountConfig] = useState(getAccountState());

  const contextValue = {
    apiValue: {
      accessToken,
      setAccessToken,
      accountConfig,
      setAccountConfig,
    },
  };

  useEffect(() => {
    window.sessionStorage.setItem(
      "AccountConfig",
      JSON.stringify(accountConfig)
    );
    window.sessionStorage.setItem("AccessToken", JSON.stringify(accessToken));
  }, [accessToken, accountConfig]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export { useUserContext, UserContextProvider };
