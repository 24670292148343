import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Dashboard from "../components/dashboard/dashboard";

const DashboardContainer = () => {
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      <Dashboard />
    </div>
  );
};
export default DashboardContainer;
