export const Messages = {
  InvalidEmailMessage: "Invalid email!",
  RequiredEmailMessage: "Email is required!",
  InvalidDateMessage: "is not a valid date. mm/dd/yyyy expected",
  InvalidWageMessage: "Please enter a correct Wage format.",
  InvalidRetroMessage:
    "We are unable to locate your record in our system using theinformation you provided. Please try again.",
  UserNotExists: "User with given email doesn't exists",
  UserAlreadyExists: "User already exists, please use Forgot Password link!",
  EmailSuccess: "Email Sent Successfully.",
  EmailFailed: "Email Send Failed!",
  FirstNameRequired: "First Name is required",
  LastNameRequired: "Last Name is required",
  CompanyNameRequired: "Company is required",
  PasswordRequired: "Password is required",
  StreetRequired: "Street is required",
  SuiteRequired: "Suite is required",
  StateRequired: "State is required",
  ZipRequired: "Zip is required",
  CityRequired: "City is required",
  TooLong: "Too Long!",
  TooShort: "Too Short!",
  PasswordMinLength: "Password is too short - should be 8 chars minimum.",
};

export const Record_Success_Message = "Record has been saved.";
export const Record_Failure_Message =
  "Something went wrong. Please try again later!";
export const Token_Expired_Message = "Session has expired, please login again!";
export const Payment_successful_Message = "Payment Successful";
export const Payment_Submitted_Status = "Payment Summitted";
export const Payment_unsuccessful_Message =
  "Payment Unsuccessful, try agian later";
export const Company_Record_Not_Found_Message =
  "Associated Company Details not found.";
export const Company_Record_Exist_Message =
  "Company Name or Phone or Email already exist.";
export const Lead_Record_Exist_Message = "Lead information already exist.";
export const User_Record_Exist_Message = "Email already exist.";
export const Service_Not_Available =
  "Service is unreachable. Please try again later or contact support!";
export const Confirmation_Message =
  "Thank you for the order. Please copy the Payment Confirmation Number for your reference.";
export const Product_Record_Not_Found_Message =
  "Product not found for the associated order";
export const Product_Description =
  "This is our premium service we offer as our standard to all our customers.";
export const Invoice_Status = "Created";
export const Invoice_Terms_and_Conditions =
  "By using our product, you agree to abide by the terms and conditions outlined herein, including but not limited to limitations of liability, intellectual property rights, and governing law.";
export const Invoice_Description =
  "Our product is a versatile solution designed to meet your needs with efficiency and reliability.";
