//import User from "../components/user/signUp";
import UserComponent from "../components/user/user-component";

export const UserInfoContainer = () => {

    return (
        <div>
            <UserComponent> </UserComponent>
        </div>
    );
};


export default UserInfoContainer;