import React, { useState, useEffect } from "react";
import { Route, Routes, useRoutes, Outlet, Navigate } from "react-router-dom";
import * as ROUTE_CONSTANT from "../constant/routeConstant";
import LoginContainer from "../containers/login-module/login-container";
import PrivateRoutes from "./privateRoutes";
import PublicRoutes from "./publicRoutes";
import NotFound from "../components/Common/NotFound";
import SignUpContainer from "../containers/login-module/signup-container";
import ResetPassContainer from "../containers/login-module/reset-password-container";
import DashboardContainer from "../containers/dashboard-container";
import UserInfoContainer from "../containers/userInfo-container";
import CompanyContainer from "../containers/company-main-container";
import CompanyAddOnContainer from "../containers/company-addOn-container";
import AgreementContainer from "../containers/agreement-container";
import PaymentContainer from "../containers/payment-container";
import InvoiceContainer from "../containers/invoice-container";
import ReviewSignContainer from "../containers/reviewSign-container";
import CrudUser from "../components/user/user-management/CrudUser";
import OrderContainer from "../containers/order-container";
import CompanyAddressContainer from "../containers/company-address-container";
import ConfirmationPage from "../components/submission/confirmation";
import LeadContainer from "../containers/login-module/lead-container";
//import axios from "axios";

import {
  getToken,
  getUser,
  removeUserSession,
  setUserSession,
} from "../utility/userUtil";

export const RouteModule = () => {
  const user = getUser();
  const [authLoading, setAuthLoading] = useState(false);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    // axios
    //   .get(`http://localhost:4000/verifyToken?token=${token}`)
    //   .then((response) => {
    //     setUserSession(response.data.token, response.data.user);
    //     setAuthLoading(false);
    //   })
    //   .catch((error) => {
    //     removeUserSession();
    //     setAuthLoading(false);
    //   });
  }, []);

  // if (authLoading && getToken()) {
  //   return <div className="content">Checking Authentication...</div>;
  // }
  const routes = [];
  if (user?.type === 1) {
    routes.push(
      { path: "company", element: <DashboardContainer /> },
      { path: "user", element: <UserInfoContainer /> },
      { path: "company", element: <CompanyContainer /> },
      { path: "address", element: <CompanyAddressContainer /> },
      { path: "agreement", element: <AgreementContainer /> },
      { path: "payment", element: <PaymentContainer /> },
      { path: "docsign", element: <ReviewSignContainer /> },
      { path: "complete", element: <ConfirmationPage /> },
      { path: "order", element: <OrderContainer /> }
    );
  } else {
    routes.push({ path: "sales/leads", element: <LeadContainer /> });
  }

  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },
    {
      path: "/",
      element: (
        <PublicRoutes>
          <Outlet />
        </PublicRoutes>
      ),
      children: [
        { path: "login", element: <LoginContainer /> },
        {
          path: "forgotpassword",
          element: <SignUpContainer path="forgotpassword" />,
        },
        {
          path: "resetpassword",
          element: <ResetPassContainer path="resetpassword" />,
        },
        { path: "signup", element: <SignUpContainer path="signup" /> },
        {
          path: "userregistration",
          element: <ResetPassContainer path="userregistration" />,
        },
      ],
    },
    {
      element: (
        <PrivateRoutes>
          <Outlet />
        </PrivateRoutes>
      ),
      children: [...routes],
    },
    {
      element: <Outlet />,
      children: [{ path: "notfound", element: <NotFound /> }],
    },
    {
      path: "*",
      element: <Navigate to={!getUser() ? "/login" : "/notfound"} replace />,
    },
  ]);

  // return (
  //   <div className="content">
  //     <Routes>
  //       <Route path="*" element={<LoginContainer />} />
  //       <Route element={<PublicRoutes />}>
  //         <Route path="/login" element={<LoginContainer />} />
  //         <Route
  //           path="/forgotpassword"
  //           element={<SignUpContainer path="forgotpassword" />}
  //         />
  //         <Route
  //           path="/resetpassword"
  //           element={<ResetPassContainer path="resetpassword" />}
  //         />
  //         <Route path="/signup" element={<SignUpContainer path="signup" />} />
  //         <Route
  //           path="/userregistration"
  //           element={<ResetPassContainer path="userregistration" />}
  //         />
  //       </Route>
  //       <Route element={<PrivateRoutes />}>
  //         {user?.type == 1 ? (
  //           <>
  //             <Route
  //               path={ROUTE_CONSTANT.COMPANY_PAGE}
  //               element={<DashboardContainer />}
  //             />
  //             <Route path="/user" element={<UserInfoContainer />} />
  //             <Route path="/company" element={<CompanyContainer />} />
  //             <Route
  //               path={ROUTE_CONSTANT.ADDRESS_PAGE}
  //               element={<CompanyAddressContainer />}
  //             />
  //             <Route path="/agreement" element={<AgreementContainer />} />
  //             <Route path="/payment" element={<PaymentContainer />} />
  //             <Route path="/confirm" element={<InvoiceContainer />} />
  //             <Route path="/docsign" element={<ReviewSignContainer />} />
  //             <Route path="/complete" element={<ConfirmationPage />} />
  //             <Route path="/order" element={<OrderContainer />} />
  //           </>
  //         ) : (
  //           <>
  //             <Route path="/sales/leads" element={<LeadContainer />} />
  //           </>
  //         )}
  //       </Route>
  //     </Routes>
  //   </div>
  // );
};

export default RouteModule;
