import AgreementComponent from "../components/agreements/index";

const AgreementContainer = () => {
    return (
        <div>
            <AgreementComponent> </AgreementComponent>

        </div>
    )
}
export default AgreementContainer;