export function formatFax(num) {
  let cleanedNumber = ("" + num).replace(/\D/g, "");
  cleanedNumber = cleanedNumber.substring(0, 10);
  let formatChars = { 0: "(", 3: ") ", 6: "-" };
  let formattedString = "";
  for (let i = 0; i < cleanedNumber.length; i++) {
    formattedString += (formatChars[i] || "") + cleanedNumber[i];
  }
  return formattedString;
}

export function formatNumber(num) {
  if (num && typeof num === "string") {
    num = num.replace(/\D/g, "");
  }

  return num;
}
