import React, { useState, useEffect } from "react";
//import agreementDoc from "../../assets/image/Agreement_ProtoType.png";
import { getAgreementUrl } from "../../utility/documentUtil";
import { useUserContext } from "../../customHook/UserContext";
import { isNullorUndefined } from "../../utility/validator";
import { Grid, Paper, Box } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import LogoIndicator from "../../controls/LogoIndicator";
import { styled } from "@mui/material/styles";
import { useAlert } from "../../customHook/useAlert";
import { Product_Record_Not_Found_Message } from "../../constant/messages";

const PDFViewer = ({ handleBack, handleNext }) => {
  const [pdfUrl, SetPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [signedClick, setSignedClick] = useState(true);
  const showAlert = useAlert();
  const [isDisabled, setDisabled] = useState(true);
  const [openInNewTab] = useState(true);
  const {
    apiValue: { accountConfig },
  } = useUserContext();

  const Item = styled(Paper)(({ theme }) => ({
    minHeight: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "244px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "450px",
    },
    [theme.breakpoints.up("md")]: {
      width: "600px",
    },
  }));

  useEffect(() => {
    setIsLoading(true);
    if (isNullorUndefined(pdfUrl)) {
      const companyId = accountConfig?.CompanyInfo?.id;
      const userId = accountConfig?.UserInfo?.id;
      const Products = accountConfig?.Products;
      const productId = Products?.id;
      const ProductAmount = !isNullorUndefined(Products?.Unit_Price)
        ? Products.Unit_Price
        : 0;

      if (ProductAmount === 0 || isNullorUndefined(productId)) {
        showAlert(Product_Record_Not_Found_Message, "error");
        setDisabled(true);
      } else {
        setDisabled(false);
        SetPdfUrl(getAgreementUrl(companyId, userId, productId));
      }
    }
    setIsLoading(false);
  }, []);

  const handleSubmitClick = () => {
    //navigate(PAYMENT_PAGE);
    handleNext();
  };

  const handleAgreementClick = async () => {
    setIsLoading(true);
    //setSignedClick(true);
    setIsLoading(false);
    window.open(pdfUrl, "_blank", "noreferrer");
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} direction="column" alignItems="center">
        {isLoading ? (
          <LogoIndicator />
        ) : (
          <Box sx={{ mt: 2 }}>
            <Item elevation={4} square={false}>
              <Grid container item xs={12} justifyContent={"center"}>
                <Typography variant="h5" style={{ color: "#1976d2" }} my={5}>
                  Agreement
                </Typography>
              </Grid>
              <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 0, sm: 2 }}
                justifyContent={"center"}
                mt={5}
              >
                {!openInNewTab && !isNullorUndefined(pdfUrl) ? (
                  // eslint-disable-next-line jsx-a11y/iframe-has-title
                  <iframe
                    height="500px"
                    width="100%"
                    frameborder="0"
                    allowTransparency="true"
                    src={pdfUrl}
                  ></iframe>
                ) : (
                  <Box>
                    <Grid
                      container
                      item
                      justify="space-between"
                      sx={{ ml: 2, mt: 5, mb: 10, textAlign: "center" }}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        color="primary"
                        disabled={isDisabled}
                        onClick={() => handleAgreementClick()}
                      >
                        Review & Sign
                      </Button>
                    </Grid>
                  </Box>
                )}
              </Grid>
              <Grid>
                <DialogActions>
                  <Button
                    type="button"
                    className="outline"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    onClick={() => handleSubmitClick()}
                  >
                    Next
                  </Button>
                </DialogActions>
              </Grid>
            </Item>
          </Box>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default PDFViewer;
