import { Box, Grid } from "@mui/material";
import Company from "../components/company/company";
import { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Address from "../components/company/Address";
import UserRecord from "../components/user/user-record";
import Agreement from "../components/agreements/agreement-component";
import PaymentComponent from "../components/integration/integration-component";
import OrderSummary from "../components/invoice/invoice";

export const CompanyContainer = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: "Company Details",
      description: "Companydetails",
    },
    {
      label: "Address Details",
      description: "Addressdetails",
    },
    {
      label: "User Details",
      description: "Userdetails",
    },
    {
      label: "Agreement Details",
      description: "Agreementdetails",
    },
    {
      label: "Order Summary",
      description: "OrderSummary",
    },
    {
      label: "Payment Confirmation",
      description: "Paymentdetails",
    },
  ];
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={3}
        md={2}
        sx={{ background: "#FFFFFF", padding: "1rem" }}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12} sm={9} md={10}>
        <Box sx={{ height: "85vh", overflowY: "auto" }}>
          {activeStep === steps.length ? (
            <>{"Create Company"}</>
          ) : steps[activeStep].description === "Companydetails" ? (
            <Company handleNext={handleNext} />
          ) : steps[activeStep].description === "Addressdetails" ? (
            <Address handleNext={handleNext} handleBack={handleBack} />
          ) : steps[activeStep].description === "Userdetails" ? (
            <UserRecord handleNext={handleNext} handleBack={handleBack} />
          ) : steps[activeStep].description === "Agreementdetails" ? (
            <Agreement handleNext={handleNext} handleBack={handleBack} />
          ) : steps[activeStep].description === "OrderSummary" ? (
            <OrderSummary handleNext={handleNext} handleBack={handleBack} />
          ) : (
            steps[activeStep].description === "Paymentdetails" && (
              <PaymentComponent
                handleNext={handleNext}
                handleBack={handleBack}
              />
            )
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CompanyContainer;
